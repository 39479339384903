<template>
  <div class="topbar">
    <v-app-bar class="ConatinerOfHeader" color="white">
      <v-btn icon @click="$emit('togglenav')" class="hidden-lg-and-up">
        <img src="@/assets/img/menu_icon.png" alt="menu icon" />
      </v-btn>

      <div class="conRequestLinks" v-if="metaProduct.isProductPage">
        <router-link
          :class="metaProduct.isRequest ? 'activeItem' : ''"
          :to="{
            name: 'PurchasingAllRequests',
            parms: { id: $route.params.id },
          }"
        >
          All Requests
        </router-link>
        <router-link
          :class="metaProduct.isJobOrder ? 'activeItem' : ''"
          :to="{ name: 'jobOrdersRequests', parms: { id: $route.params.id } }"
        >
          Job Orders
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-menu
        content-class="conOfNotificationMenu"
        offset-y
        rounded="0"
        max-width="300"
        :left="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="bellBtnContainer"
            icon
            v-bind="attrs"
            v-on="on"
            :ripple="false"
            @click="inputOfNotification()"
          >
            <v-badge
              color="#2C77FF"
              :content="notificationNumber"
              :value="notificationNumber"
              overlap
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto conOfNotificationCard" max-width="400">
          <v-toolbar class="d-flex justify-space-between w-100 align-center">
            <v-toolbar-title
              ><div class="font-weight-bold">Notification</div>
            </v-toolbar-title>
          </v-toolbar>

          <v-list class="conOfListData scrollbar">
            <template v-if="listData && listData.length > 0">
              <v-list-item
                v-for="item in listData"
                :key="item.id"
                @click="markAsRead(item.id)"
                :class="item.read_at == null ? 'unread' : ''"
              >
                <router-link
                  :to="{
                    name:
                      item.data.action_type == 'jo'
                        ? 'jobOrdersProcess'
                        : item.data.action_type == 'comment'
                        ? 'jobOrdersProcess'
                        : item.data.action_type == 'project'
                        ? 'PurchasingAllRequests'
                        : item.data.action_type == 'cost'
                        ? 'request-process'
                        : item.data.action_type == 'request'
                        ? 'request-process'
                        : item.data.action_type == 'jo_all'
                        ? 'jobOrdersProcessWtihStatus'
                        : item.data.action_type == 'sales_jo_all'
                        ? 'jobOrdersProcessSalesWtihStatus'
                        : item.data.action_type == 'sales_jo'
                        ? 'jobOrdersProcess'
                        : item.data.action_type == 'company'
                        ? 'settingCompanies'
                        : '',
                    params: { id: item.data.action_id },
                    query: {
                      status:
                        item.data.action_type == 'sales_jo_all' ? '1' : '8', // Set status based on condition
                    },
                  }"
                >
                  <div class="conOfNotif">
                    <div class="messageNotification">
                      <span>
                        <v-icon
                          :class="
                            item.data.action === true ? 'red-icon' : 'blue-icon'
                          "
                          >mdi-bell-outline</v-icon
                        >
                      </span>
                      {{ item.data.message }}
                    </div>
                    <div class="timeOfNotification">
                      {{ item.created_at }}
                    </div>
                  </div>
                </router-link>
              </v-list-item>
              <infinite-loading
                v-if="listData.length"
                spinner="spiral"
                @infinite="infiniteScroll"
              >
                <div slot="no-more" class="noMoreNot">No more Notification</div>
                <div slot="no-results" class="noMoreNot">
                  No more Notification
                </div>
              </infinite-loading>
            </template>
            <div class="loadedNotification" v-else-if="isLoadingNotification">
              <div>
                <div class="loader">
                  <v-progress-circular
                    :size="40"
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div class="loaderNotification">Notifications Are Loaded</div>
              </div>
            </div>
          </v-list>
        </v-card>
        <v-divider></v-divider>
      </v-menu>
      <v-menu :close-on-content-click="false" bottom offset-y :left="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="conUserbtn"
            v-bind="attrs"
            v-on="on"
            :ripple="false"
            text
          >
            <div class="conOfuserControl d-flex align-center">
              <div class="conOfUserInfo mr-2">
                <div class="userName">{{ user.name }}</div>
                <div class="userTitle">{{ user.bio }}</div>
              </div>
              <div class="conUserIcon">
                <img :src="user.avatar" alt="" />
              </div>
            </div>

            <v-icon> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="conOfListUser"
            @click="$router.push({ name: 'editProfile' })"
          >
            <v-list-item-icon>
              <v-icon class="iconStyle">mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Edit Profile</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="conOfListUser"
            @click="$router.push({ name: 'changePass' })"
          >
            <v-list-item-icon>
              <v-icon class="iconStyle">mdi-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item class="conOfListUser" @click="logout">
            <v-list-item-icon>
              <v-icon class="iconStyle">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
// import  ApiService  from "@/modules/shared/services/api.js";
// let apiServices = new ApiService();
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => {
    return {
      notificationNumber: null,
      isLoadingNotificationNumber: false,
      metaProduct: null,
      isLoadingNotification: false,
      listData: [],
      pagination: {
        current_page: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    logout() {
      this.$store.commit("setToken", null);
      this.$store.commit("setUser", null);
      this.$store.commit("setExpires_at", null);
      this.$router.push({ name: "auth" });
    },
    inputOfNotification() {
      this.pagination.current_page = 1;
      this.getAllNotification(this.pagination.current_page);
      // if(this.notificationNumber > 0) this.markAsRead()
    },
    markAsRead(id) {
      let formData = {
        notification_id: id,
      };
      axios //cat-purchasing-app.cat-sw.com/api/v3/markAsRead
        .post(
          `https://cpadminv3.cat.com.eg/api/v3/markAsRead`,
          formData
        )
        .then((res) => {
          if (res) this.getUnreadNotificationsNumber();
        })
        .catch((err) => {
          this.serverResponse(err);
        });
    },
    getUnreadNotificationsNumber() {
      this.notificationNumber = null;
      this.isLoadingNotificationNumber = true;
      axios
        .get(
          `https://cpadminv3.cat.com.eg/api/v3/unreadNotificationsNumber`
        )
        .then((res) => {
          this.isLoadingNotificationNumber = false;
          this.notificationNumber = res.data.unreadNotificationsNumber;
        })
        .catch((err) => {
          console.log(err);
          // this.serverResponse(err);
        });
    },
    getAllNotification(page) {
      this.listData = [];
      this.isLoadingNotification = true;
      axios
        .get(
          `https://cpadminv3.cat.com.eg/api/v3/notifications?page=${page}`
        )
        .then((res) => {
          console.log("res", res);
          this.isLoadingNotification = false;
          this.listData = res.data.data;
          this.pagination = res.data.meta;
        })
        .catch((err) => {
          console.log(err);
          // this.serverResponse(err);
        });
    },
    infiniteScroll($state) {
      setTimeout(() => {
        this.pagination.current_page++;
        axios
          .get(
            `https://cpadminv3.cat.com.eg/api/v3/notifications?page=${this.pagination.current_page}`
          )
          .then((response) => {
            if (response.data.data.length > 1) {
              response.data.data.forEach((item) => this.listData.push(item));
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);
    },
  },
  created() {
    this.getUnreadNotificationsNumber();
  },
  watch: {
    $route: {
      handler: function (to) {
        this.metaProduct = to.meta;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.red-icon {
  border-radius: 15px;
  color: red !important;
}
.blue-icon {
  border-radius: 15px;
  color: rgb(90, 90, 245) !important;
}
::v-deep {
  .theme--light.v-btn:focus::before {
    opacity: 0;
  }
}
</style>
