<template>
  <div class="conOfLoginPage fill-height">
    <v-form ref="resetform" class="fill-height conOfLoginForm">
      <v-container fluid class="fill-height">
        <v-row class="fill-height">
          <v-col md="4" class="hidden-sm-and-down fill-height">
            <div class="conBollutsImg pt-6 pl-2">
              <img
                class="img-fluid"
                src="@/assets/img/loginBollitsWhite.png"
                alt="logo"
              />
            </div>
          </v-col>
          <v-col md="4" cols="12">
            <div class="formCard fill-height">
              <div class="conOfFormSection fill-height">
                <v-row>
                  <v-col cols="12">
                    <div class="loginHeader">
                      <img
                        class="img-fluid"
                        src="@/assets/img/loginLogo.png"
                        alt="logo"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      placeholder="Email Address"
                      v-model="form.email"
                      class="formInput"
                      required
                      :error-messages="emailErrors"
                      @input="$v.form.email.$touch()"
                      @blur="$v.form.email.$touch()"
                      solo
                      hide-details="auto"
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12 pb-2">
                    <v-text-field
                      outlined
                      class="formInput"
                      placeholder="Password"
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.password.$touch()"
                      @blur="$v.form.password.$touch()"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12 pb-2">
                    <v-text-field
                      outlined
                      class="formInput"
                      placeholder="Password confirmation"
                      v-model="form.password_confirmation"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.password_confirmation.$touch()"
                      @blur="$v.form.password_confirmation.$touch()"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="conLoginFooter px-3">
                      <div class="btnLoginCon">
                        <v-btn
                          class="primaryBtn loginBtn"
                          @click="resetPass"
                          text
                          tile
                          :disabled="isLoading"
                          :loading="isLoading"
                        >
                          Update
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="successMessage">
                    <div class="vaidMessage">
                      Password updated please click here to
                      <router-link to="/login">login</router-link>!
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="validMessage">
                    <div class="vaidMessage">
                      Please follow the right link with correct data!
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col md="4" class="hidden-sm-and-down fill-height">
            <div
              class="
                conBollutsImg
                pb-6
                pr-2
                fill-height
                d-flex
                align-end
                justify-end
              "
            >
              <img
                class="img-fluid"
                src="@/assets/img/loginBollitsOrange.png"
                alt="logo"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  name: "ResetPasswordForm",
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: { required },
      password_confirmation: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      validMessage: false,
      successMessage: false,
      // email: "admin@admin.com",
      // password: "12345678",
      form: {
        token: this.$route.params.token,
        email: "",
        password: "",
        password_confirmation: "",
      },
      showPassword: false,
    };
  },
  methods: {
    resetPass() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoading = true;
      apiServices.post("forget-password/reset", this.form).then((res) => {
        if (res) {
          this.$refs.resetform.reset();
          this.$v.$reset();
          this.validMessage = false;
          this.successMessage = true;
          this.isLoading = false;
          //this.$router.push("/");
        } else {
          this.validMessage = true;
          this.successMessage = false;
          this.isLoading = false;
        }
      });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Must be valid e-mail");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required.");
      return errors;
    },
  },
};
</script>
<style lang="scss">
@import "./_login.scss";
</style>
