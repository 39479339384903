<template>
  <transition name="show">
    <div class="sidebar fill-height" v-if="open">
      <div class="conTopLinks fill-height">
        <div class="conOfLogo">
          <img
            class="img-fluid px-2"
            src="@/assets/img/side-logo.png"
            alt="logo"
          />
        </div>
        <div class="conOfMenuSidebar d-flex justify-center">
          <div>
            <router-link class="eachLinkSidbar" :to="{ name: 'landingPage' }">
              <v-icon>mdi-home</v-icon>
            </router-link>
            <router-link
              class="eachLinkSidbar"
              :to="{ name: 'allProductsReport' }"
            >
              <v-icon>mdi-comment-text</v-icon>
            </router-link>
            <router-link
              class="eachLinkSidbar"
              :to="{ name: 'AlljobOrdersRequests' }"
            >
              <v-icon>mdi-file</v-icon>
            </router-link>
            <router-link class="eachLinkSidbar" :to="{ name: 'TMPages' }">
              <v-icon>mdi-clipboard-account-outline</v-icon>
            </router-link>
            <router-link
              class="eachLinkSidbar"
              :to="{ name: 'settingProducts' }"
            >
              <v-icon>mdi-cog</v-icon>
            </router-link>
          </div>
          <div class="vesion"><a>V 3.1.0</a></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["open"],
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
