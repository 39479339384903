<template>
  <v-app class="v-app-Style fill-height">
    <main class="mainStyle d-flex fill-height" :class="this.isSideBar ? 'sideBarStyle' : ''">
      <Sidebar :open="navOpen" />
      <div class="conAllCon">
        <Header @togglenav="navOpen = !navOpen" />
        <div class="contentPages scrollbar">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </v-app>
</template>

<script>
import Header from "@/modules/shared/components/layout/Header";
import Sidebar from "@/modules/shared/components/layout/Sidebar";

export default {
  data() {
    return {
      navOpen: this.$vuetify.breakpoint.mobile ? false : true,
      isSideBar: false,
    };
  },
  components: {
    Header,
    Sidebar,
  },
  watch: {
    $route(to) {
      this.isSideBar = to.meta.sideBarValid;
    },

    deep: true,
    immediate: true,
  },
};
</script>
