<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Check IN</div>
        </div>
      </v-col>
      <v-row class="d-flex justify-center">
        <v-col md="6" sm="6" cols="12">
          <div v-if="hideFiled">
            <label class="signinLable">Project Name</label>

            <v-select
              outlined
              v-model="formItem.project_id"
              :items="projectList"
              @change="getTasksList(formItem.project_id)"
              item-text="project_name"
              item-value="id"
              placeholder="project Name"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
              required
              clearable
              :error-messages="project_nameErrors"
              @input="$v.formItem.project_id.$touch()"
              @blur="$v.formItem.project_id.$touch()"
            >
            </v-select>

            <label class="signinLable">Tasks</label>

            <v-select
              outlined
              v-model="formItem.task_id"
              :items="tasksList"
              item-text="name"
              item-value="id"
              placeholder="task Name"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
              required
              :error-messages="task_nameErrors"
              @input="$v.formItem.task_id.$touch()"
              @blur="$v.formItem.task_id.$touch()"
            ></v-select>
            <v-radio-group
              required
              :error-messages="typeErrors"
              @input="$v.formItem.type.$touch()"
              @blur="$v.formItem.type.$touch()"
              v-model="formItem.type"
              row
            >
              <v-radio label="On Line" color="success" value="1"></v-radio>
              <v-radio label="On Site" color="success" value="2"></v-radio>
            </v-radio-group>
          </div>

          <div v-if="!hideFiled">
            <p class="timerStyle text-center">{{ time | secondsInMinutes }}</p>
          </div>
          <div class="text-center">
            <v-btn
              v-if="hideFiled"
              @click="signin()"
              :disabled="disabled"
              class="btnPrimary pr-2"
              >Start</v-btn
            >
            <div v-if="!hideFiled">
              <v-btn @click="breakTask()" class="btnPrimary pr-2">Braek</v-btn>
              <v-btn @click="dialogSwitchProject = true" class="btnPrimary"
                >Switch Project</v-btn
              >
              <v-btn @click="leaveTask()" class="btnPrimary">Leave</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog v-model="dialogSwitchProject" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Switch Project</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col>
                <label class="signinLable">Project Name</label>

                <v-select
                  outlined
                  v-model="formItem.project_id"
                  :items="projectList"
                  @change="getTasksList(formItem.project_id)"
                  item-text="project_name"
                  item-value="id"
                  placeholder="project Name"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  append-icon="mdi-chevron-down"
                  required
                  :error-messages="project_nameErrors"
                  @input="$v.formItem.project_id.$touch()"
                  @blur="$v.formItem.project_id.$touch()"
                >
                </v-select>

                <label class="signinLable">Tasks</label>

                <v-select
                  outlined
                  v-model="formItem.task_id"
                  :items="tasksList"
                  item-text="name"
                  item-value="id"
                  placeholder="task Name"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  append-icon="mdi-chevron-down"
                  required
                  :error-messages="task_nameErrors"
                  @input="$v.formItem.task_id.$touch()"
                  @blur="$v.formItem.task_id.$touch()"
                ></v-select>

                <v-radio-group
                  v-model="formItem.type"
                  required
                  :error-messages="typeErrors"
                  @input="$v.formItem.type.$touch()"
                  @blur="$v.formItem.type.$touch()"
                  row
                >
                  <v-radio label="On Line" color="success" value="1"></v-radio>
                  <v-radio label="On Site" color="success" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogSwitchProject = false">
            Cancel
          </v-btn>
          <v-btn class="btnPrimaryOrg" @click="switchProject()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        type: { required },
        project_id: { required },
        task_id: { required },
      },
    };
    return validations;
  },
  data() {
    return {
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      isCounterRunning: false,
      successMessage: "",
      errorMessage: "",
      dialogSwitchProject: false,
      hideFiled: true,
      hideBtns: false,
      projectList: [],
      disabled: false,
      selectedProjectId: null,
      tasksList: [],
      isLoading: false,
      listData: {
        project_name: "",
      },

      formItem: { type: "", task_id: "", project_id: "" },
      timerDialoge: false,
      time: 0,
      timer: null,
    };
  },

  name: "stopwatch",
  props: {
    running: {
      type: Boolean,
      default: false,
    },
    resetWhenStart: {
      type: Boolean,
      default: false,
    },
    restWhenStop: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    running: function (newVal) {
      if (newVal) this.startT();
      else this.stopT();
    },
  },
  mounted() {
    if (this.running) this.startT();
  },
  computed: {
    // ...mapGetters(["counter", "isCounterRunning", "apiMessage"]),
    ...mapGetters(["apiMessage"]),
    project_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.project_id.$dirty) return errors;
      !this.$v.formItem.project_id.required &&
        errors.push("project Name Is Required.");
      return errors;
    },
    task_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.task_id.$dirty) return errors;
      !this.$v.formItem.task_id.required &&
        errors.push("Task Name Is Required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.formItem.type.$dirty) return errors;
      !this.$v.formItem.type.required &&
        errors.push("Will You Work Online or Onsite !");
      return errors;
    },
  },
  methods: {
    startT: function () {
      this.$v.$touch();
      if (this.$v.formItem.$error) return;

      if (this.resetWhenStart) this.resetT();

      this.timer = setInterval(() => {
        this.time++;
        this.timerDialoge = true;
      }, 1000);

      // Update Vuex state
      this.$store.commit("setCounterRunning", true); // Update isCounterRunning to true
    },
    stopT: function () {
      clearInterval(this.timer);

      // Update Vuex state
      this.$store.commit("setCounterRunning", false); // Update isCounterRunning to false

      if (this.restWhenStop) this.resetT();
    },
    resetT: function () {
      this.time = 0;
    },

    signin() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoading = true;

      apiServices
        .get(
          `start/task?task_id=${this.formItem.task_id}&type=${this.formItem.type}`
        )
        .then((res) => {
          if (res) {
            this.successMessage = res.message;
            this.successSnackbar = true;
            this.isLoading = false;
            this.hideFiled = false;
            this.hideBtns = true;
            console.log("successMessage", this.successMessage);
            this.timer = setInterval(() => {
              this.time++;
              this.timerDialoge = true;
            }, 1000);
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage);
            errors.forEach((err) => {
              this.errorMessage = err;
            });
            this.errorSnackbar = true;
          }
        });

      // if (this.$v.formItem.$error) return;
      // if (this.resetWhenStart) this.resetT();
      // this.timer = setInterval(() => {
      //   this.time++;
      //   this.timerDialoge = true;
      // }, 1000);
    },
    leaveTask() {
      this.isLoading = true;
      this.hideFiled = true;
      this.hideBtns = false;
      apiServices.get(`leave/task`).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;

          console.log(res);
          this.isLoading = false;
          this.stopT();
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.validMessage = true;
          this.isLoading = false;
        }
      });

      if (this.$v.formItem.$error) return;
    },
    breakTask() {
      this.isLoading = true;

      apiServices.get(`break/task`).then((res) => {
        if (res) {
          console.log(res);
          this.isLoading = false;
          this.stopT();
          this.disabled = false;
          this.hideBtns = false;
          this.hideFiled = true;
          this.successMessage = res.message;
          this.successSnackbar = true;
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.validMessage = true;
          this.isLoading = false;
        }
      });

      if (this.$v.formItem.$error) return;
    },
    switchProject() {
      this.isLoading = true;
      apiServices
        .get(
          `switch/task?task_id=${this.formItem.task_id}&type=${this.formItem.type}`
        )
        .then((res) => {
          if (res) {
            console.log(res);
            this.isLoading = false;
            this.dialogSwitchProject = false;
            this.hideFiled = false;
            this.successMessage = res.message;
            this.successSnackbar = true;
            console.log("Sucessss,", res.message);
          } else {
            this.validMessage = true;
            this.isLoading = false;
            this.dialogSwitchProject = false;
            this.errorMessage = res.message || "Somthing Error";
            this.errorSnackbar = true;
            console.log("errrrror,", res.message);
          }
        });

      if (this.$v.formItem.$error) return;
      // if (this.resetWhenStart) this.resetT();
      // this.timer = setInterval(() => {
      //   this.time++;
      //   this.timerDialoge = true;
      // }, 1000);
    },
    getProjectList() {
      this.projectList = [];
      this.isLoadingUsersList = true;
      apiServices.get(`done/projects`).then((res) => {
        if (res) {
          this.projectList = res.data;
          this.isLoadingUsersList = false;
        } else this.isLoadingUsersList = false;
      });
    },
    getTasksList(project_id) {
      this.tasksList = [];

      this.isLoadingTasksList = true;
      apiServices.get(`tasks?project_id=${project_id}`).then((res) => {
        if (res) {
          this.tasksList = res.data;

          this.isLoadingTasksList = false;
        } else this.isLoadingTasksList = false;
      });
    },
  },

  filters: {
    secondsInMinutes: function (seconds) {
      return moment("2015-01-01")
        .startOf("day")
        .seconds(seconds)
        .format("HH:mm:ss");
    },
  },
  created() {
    const isCounterRunning = localStorage.getItem("isCounterRunning");
    if (isCounterRunning === "true") {
      // Update Vuex state if the counter was running when the page was last open
      this.$store.commit("setCounterRunning", true);
      // Start the timer
      this.startT();
    }
    // this.getProjectList();
    this.getProjectList();
    this.getTasksList();
  },
};
</script>
<style lang="scss" scoped>
@import "./_signin.scss";
</style>
