<template>
  <div class="fill-height">
    <MessageService />
    <AppNoLayout v-if="$route.meta.noLayout" />
    <AppLayout v-else /> 
  </div>
</template>

<script>
import axios from "axios";
// import router from "../../../router/index";
import store from "@/store/index";
import AppLayout from "@/modules/shared/components/app-layout";
import AppNoLayout from "@/modules/shared/components/app-no-layout";
import MessageService from "@/modules/shared/components/message-service";
export default {
  components: {
    AppLayout,
    AppNoLayout,
    MessageService,
  },

  data: () => ({
    timeToRefresh: 2900000,
  }),
  methods: {
    /* refreshToken(){
        console.log("enter");
      let exp = parseInt(store.getters.expires_at);
       const now = Date.now() / 1000;
       let timeUntilRefresh = exp - now;
       console.log("Notttt refreshed", timeUntilRefresh);
    
      if (timeUntilRefresh < 3550) {
         console.log("token is refreshed", timeUntilRefresh);
         axios
           .post("auth/refresh")
           .then((res) => {
             console.log(res.data.access_token);
               store.commit("setToken", res.data.access_token);
              store.commit("setUser", res.data.me);
               store.commit("setExpires_at", res.data.expires_at);
             
          })
           .catch((error) => {
            console.error(error);
          });
}} */
  },
  created() {
    setInterval(() => {
      const now = Date.now() / 1000;
      let exp = parseInt(store.getters.expires_at);
      let timeUntilRefresh = exp - now;
      this.timeToRefresh = timeUntilRefresh * 1000 - 600000;

      console.log("token is refreshed", timeUntilRefresh);
      axios
        .post("auth/refresh")
        .then((res) => {
          store.commit("setToken", res.data.access_token);
          store.commit("setUser", res.data.me);
          store.commit("setExpires_at", res.data.expires_at);
        })
        .catch((error) => {
          console.error(error);
        });
    }, this.timeToRefresh);
  },
};
</script>
