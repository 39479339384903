import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "./assets/styles/main.scss";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

import InfiniteLoading from "vue-infinite-loading";
import VTooltip from "v-tooltip";
import "./assets/tooltip.css";
import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce)

Vue.use(VTooltip);
Vue.component("infinite-loading", InfiniteLoading);

Vue.config.productionTip = false;

Vue.filter("formatDate", function (value) {
  if (value) {
    var myDate = new Date(value);
    var year = myDate.getFullYear();
    var mes = myDate.getMonth() + 1;
    var dia = myDate.getDate();
    var hours = myDate.getHours();
    var min = myDate.getMinutes();

    var filteredDate =  dia + "-" + mes + "-" + year + " " +hours + ":"+ min ;
    return filteredDate;
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
